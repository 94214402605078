import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div style={{ background: 'black' }}>
      <h1>NO REQUESTS</h1>
      <p>You just requested a song that the dj didn&#39;t want to play... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
